<script setup lang="ts">
import { useVoixTheme } from '@voix/voix-layer/composables/useVoixTheme'

const { themeColor, themeColorLight, themeColorLightest, themeColorDark, themeColorDarkest } = useVoixTheme()
</script>

<template>
  <div>
    <slot />
  </div>
</template>

<style lang="scss">
.page-enter-active,
.page-leave-active {
  transition: all 0.3s;
}

.page-enter-from,
.page-leave-to {
  opacity: 0;
  filter: blur(1rem);
}

.voix-admin-bg-dark {
  background-color: v-bind(themeColorDark);
}

.hover\:voix-admin-bg-dark {
  &:hover {
    background-color: v-bind(themeColorDark);
  }
}

.voix-admin-bg {
  background-color: v-bind(themeColor);
}

.hover\:voix-admin-bg {
  &:hover {
    background-color: v-bind(themeColor);
  }
}

.voix-admin-bg-light {
  background-color: v-bind(themeColorLight);
}

.hover\:voix-admin-bg-light {
  &:hover {
    background-color: v-bind(themeColorLight);
  }
}

.voix-admin-bg-lightest {
  background-color: v-bind(themeColorLightest);
}

.hover\:voix-admin-bg-lightest  {
  &:hover {
    background-color: v-bind(themeColorLightest);
  }
}

.voix-admin-text-dark {
  color: v-bind(themeColorDark);
}

.hover\:voix-admin-text-dark  {
  &:hover {
    color: v-bind(themeColorDark);
  }
}

.voix-admin-text-darkest {
  color: v-bind(themeColorDarkest);
}

.hover\:voix-admin-text-darkest  {
  &:hover {
    color: v-bind(themeColorDarkest);
  }
}

.voix-admin-text {
  color: v-bind(themeColor);
}

.hover\:voix-admin-text {
  &:hover {
    color: v-bind(themeColor);
  }
}

.voix-admin-text-light {
  color: v-bind(themeColorLight);
}

.hover\:voix-admin-text-light {
  &:hover {
    color: v-bind(themeColorLight);
  }
}

.voix-admin-text-lightest {
  color: v-bind(themeColorLightest);
}

.hover\:voix-admin-text-lightest {
  &:hover {
    color: v-bind(themeColorLightest);
  }
}

.voix-admin-border-dark {
  border-color: v-bind(themeColorDark);
}

.hover\:voix-admin-border-dark {
  &:hover {
    border-color: v-bind(themeColorDark);
  }
}

.voix-admin-border-darkest {
  border-color: v-bind(themeColorDarkest);
}

.hover\:voix-admin-border-darkest {
  &:hover {
    border-color: v-bind(themeColorDarkest);
  }
}

.voix-admin-border {
  border-color: v-bind(themeColor);
}

.hover\:voix-admin-border {
  &:hover {
    border-color: v-bind(themeColor);
  }
}

.voix-admin-border-light {
  border-color: v-bind(themeColorLight);
}

.hover\:voix-admin-border-light {
  &:hover {
    border-color: v-bind(themeColorLight);
  }
}

.voix-admin-border-lightest {
  border-color: v-bind(themeColorLightest);
}

.hover\:voix-admin-border-lightest {
  &:hover {
    border-color: v-bind(themeColorLightest);
  }
}

.voix-admin-border-dark {
  border-color: v-bind(themeColorDark);
}

.hover\:voix-admin-border-dark {
  &:hover {
    border-color: v-bind(themeColorDark);
  }
}

.voix-admin-border-darkest {
  border-color: v-bind(themeColorDarkest);
}

.hover\:voix-admin-border-darkest {
  &:hover {
    border-color: v-bind(themeColorDarkest);
  }
}
</style>
